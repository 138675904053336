import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

import { TabsContentProducts } from '@mshops-components-library/tab-with-items';
import { useRenderContext } from '../../pages/home/context';
import { trackEvent } from '../../utils/tracking';

const EnhancedTabWithItems = (props) => {
  const { name, children, with_card_action: withCardAction } = props;
  const { theme, shopModel } = useRenderContext();
  const sellerId = shopModel?.shop?.id;

  const componentType = name.includes('CollectionGrid') ? 'ProductsGallery' : 'ProductsCarousel';

  const getTrackingInfo = (childProperties) => {
    const variationsQuantity = childProperties?.node?.properties?.item?.variations_qty;
    const isCartElegible = childProperties?.node?.properties?.item?.product_cart_info?.is_cart_eligible;
    let path = '/home/add_cart_action';

    if (variationsQuantity && variationsQuantity > 1) {
      path = '/home/choose_item_option';
    } else if (!isCartElegible) {
      path = '/home/buy_action';
    }

    return withCardAction ? ({
      trackingData: {
        melidata_event: {
          event_data: {
            seller_id: sellerId,
            component_type: componentType,
          },
          path,
        },
      },
      trackEvent,
    }) : {};
  };

  return (
    <TabsContentProducts layoutTheme={theme} {...props} componentType={componentType}>
      {children.map((child) => (
        cloneElement(child, { ...child.props, ...props, ...getTrackingInfo(child.props) })
      ))}
    </TabsContentProducts>
  );
};

EnhancedTabWithItems.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  with_card_action: PropTypes.bool.isRequired,
};

export default EnhancedTabWithItems;
